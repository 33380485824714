

import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import IDeclarationForm from '@/types/IDeclarationForm';
import ICountry from '@/types/ICountry';
import {required} from '@/utils/validationRules';
import AuthService from '@/services/AuthService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import DatePicker from '@/components/DatePicker.vue';
import TaxDeclarationService from '@/services/TaxDeclarationService';
import {cleanupEmptyProps} from '@/utils/universalUtils';

@Component({
  name: 'DeclarationForm',
  components: {DatePicker},
})
export default class DeclarationForm extends Vue {
  @Prop()
  value!: boolean;

  loading: boolean = false;

  form: IDeclarationForm = {
    countryId: 0,
    dateFrom: '',
    dateTo: '',
    vatin: '',
    vat21: false,
  };

  rules = {
    countryId: [required],
    dateFrom: [required],
    dateTo: [],
    vatin: [required],
  };

  countries: ICountry[] = [];
  isFirstLoad: boolean = true;

  @Watch('value')
  onValueChange(val: boolean) {
    if (val) {
      if (this.isFirstLoad) {
        this.fetchCountries();
        this.isFirstLoad = false;
      }
    } else {
      this.form = {
        countryId: 0,
        dateFrom: '',
        dateTo: '',
        vatin: '',
        vat21: false,
      };
      // @ts-ignore
      this.$refs.form.resetValidation();
    }
  }

  async fetchCountries() {
    try {
      this.countries = await AuthService.getRegisterForm();
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  @Emit('input')
  toggleVisibility() {
    return !this.value;
  }

  async save() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await TaxDeclarationService.save(cleanupEmptyProps(this.form));
        this.$emit('saved');
        this.toggleVisibility();
        notify(
            NotificationTypes.success,
            this.$t('SYSTEM_SAVED'),
            this.$t('SYSTEM_DECLARATION_SAVED'),
        );
      } catch (e) {
        resolveError(e, 'save');
      } finally {
        this.loading = false;
      }
    }
  }
}
